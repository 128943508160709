//CORE
import { Link } from "react-router-dom";

//CUSTOM
import { SITE_CONFIG } from "Helpers/Constants";
import { URL_DASHBOARD } from "Helpers/Paths";

const Logo = (props) => {
  return (
    <div className="isoLogoWrapper">
      {props.collapsed ? (
        <div>
          <h3>
            <Link to={URL_DASHBOARD}>
              <img
                src={SITE_CONFIG.siteIcon}
                style={{ width: 45, height: 45 }}
                alt={SITE_CONFIG.siteName}
              />
            </Link>
          </h3>
        </div>
      ) : (
        <h3>
          <Link to={URL_DASHBOARD}>
            <div className="site-icon"></div>
          </Link>
        </h3>
      )}
    </div>
  );
};

export default Logo;
