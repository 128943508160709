import styled from "styled-components";

export const LogoutModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 18px;
    font-weight: 500;
  }

  .text {
    margin: 10px 0;
  }

  .action-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 15px;
  }
`;
